import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ProtectedRoute } from "./protected.route";
import Login from "./components/Login/Login.jsx";
import SearchPage from "./components/SearchPage/SearchPage.jsx";
function App() {
  return (
    <div className="wrapper">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Login} />
          <ProtectedRoute exact path="/search" component={SearchPage} />
          <Route path="*" component={() => "404 NOT FOUND"} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}
export default App;
