import { useState, useEffect } from "react";
import ProjectTable from "../ProjectTable/ProjectTable";
import mainLogo from "../../assets/connectedlogo.png";
import Spinner from "../Spinner/Spinner";
import "./SearchPage.css";
const SearchPage = props => {
  const [input, setInput] = useState("");
  const [projectDataDefault, setProjectDataDefault] = useState();
  const [projectData, setProjectData] = useState();

  const fetchData = async () => {
    try {
      await fetch(`${process.env.REACT_APP_SERVER}/info`)
        .then(response => response.json())
        .then(data => {
          setProjectData(
            data.data.filter(y => {
              return y.Client !== undefined;
            })
          );
          setProjectDataDefault(
            data.data.filter(y => {
              return y.Client !== undefined;
            })
          );
        });
    } catch (error) {
      console.error(error);
    }
  };

  const updateInput = async input => {
    const filtered = projectDataDefault.filter(data => {
      return (
        JSON.stringify(data.Client)
          .toLowerCase()
          .includes(input.toLowerCase()) ||
        JSON.stringify(data.Technologies)
          .toLowerCase()
          .includes(input.toLowerCase()) ||
        JSON.stringify(data.Project).toLowerCase().includes(input.toLowerCase())
      );
    });
    setInput(input);
    setProjectData(filtered);
  };

  useEffect(() => {
    fetchData();
  }, []);
  

  window.addEventListener('beforeunload', function (e) {
  props.history.push("/search")
});
  return (
    <div className="text-primary dark:text-secondary pt-3 pb-20 flex flex-col justify-center items-center font-connected">
      <img src={mainLogo} alt="logo" className="w-80" />
      <h1 className="text-xl text-blue font-connected mb-6 pl-44">
        Project Directory
      </h1>
      <input
        disabled={!projectData ? true : false}
        type="search"
        className="w-96 p-2 rounded-md disabled:opacity-30 border border-primary mb-4 dark:text-primary"
        value={input}
        placeholder={"Search for a client, project or technology ..."}
        onChange={e => updateInput(e.target.value)}
      />
      {!projectData ? (
        <>
          <div className="mt-8">Loading projects, please wait ... </div>
          <Spinner />
        </>
      ) : (
        <ProjectTable projectData={projectData} />
      )}
    </div>
  );
};

export default SearchPage;