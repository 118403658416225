import { useState, useEffect, useRef } from "react";
import Summary from "../Summary/Summary";
import "./ProjectTable.css";

const ProjectTable = ({ projectData = [] }) => {
  const [showSummary, setShowSummary] = useState(false);
  const [SummaryData, setSummaryData] = useState("");
  const ref = useRef();

  // Closes summary modal if user clicks outside the modal
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      setShowSummary(false);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref]);

  // Closes summary modal if user presses Esc key
  useEffect(() => {
    const close = e => {
      if (e.keyCode === 27) {
        setShowSummary(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  const rowClick = data => {
    setShowSummary(true);
    setSummaryData(data);
  };

  return (
    <>
      <table className="text-primary dark:text-secondary text-center font-connected w-11/12">
        <thead>
          <tr className="border-b-2 border-blue">
            <th className="text-blue text-2xl w-40">Client</th>
            <th className="text-blue text-2xl w-40">Project</th>
            <th className="text-blue text-2xl w-40">Technology</th>
          </tr>
        </thead>
        <tbody>
          {projectData &&
            projectData.map((data, index) => (
              <tr
                key={index}
                className="border-b border-primary dark:border-secondary rounded-xl p-1 even:bg-gray-light dark:even:text-primary dark:even:bg-gray-dark hover:bg-blue-light dark:hover:bg-teal-dark dark:hover:bg-opacity-90 cursor-pointer transition duration-300 ease-in-out w-96"
                onClick={() => rowClick(data)}
                tabIndex="0"
                onKeyPress={e => e.key === "Enter" && rowClick(data)}
              >
                <td className="h-auto w-20">{data.Client}</td>
                <td className="h-auto w-20">{data.Project}</td>
                <td className="h-auto w-96">
                  {JSON.stringify(data.Technologies)
                    .replace(/[[\]']+/g, "")
                    .replace(/"/g, " ")}
                </td>
              </tr>
            ))}
          {projectData.length === 0 && (
            <tr>
              <td className="h-auto w-20"></td>
              <td className="h-auto w-20"></td>
              <td className="h-auto w-96"></td>
            </tr>
          )}
        </tbody>
      </table>
      {projectData.length === 0 && <div className="m-5">No results found</div>}
      {showSummary && (
        <div className="fixed top-0 left-0 h-screen w-screen bg-gray-400 dark:bg-primary bg-opacity-50 backdrop-blur-sm backdrop-filter">
          <div className="summary z-10" ref={ref}>
            <Summary data={SummaryData} setShow={setShowSummary} />
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectTable;
