const Summary = ({ data, setShow }) => {
  return (
    <div className="border-4 border-blue bg-secondary dark:bg-primary dark:text-secondary max-h-96 overflow-y-scroll w-96 rounded-md flex flex-col p-4 shadow-2xl">
      <button
        className="flex self-end hover:text-blue"
        onClick={() => setShow(false)}
      >
        Close
      </button>
      <h1 className="text-blue dark:text-teal-light text-2xl">{`${data.Project}, ${data.Client}`}</h1>
      <p className="text-teal-dark dark:text-yellow pt-4">Summary</p>
      <p>{!data.Summary[0] ? "No summary listed" : data.Summary}</p>
      <p className="text-teal-dark dark:text-yellow pt-4">Technologies</p>
      <p>{!data.Technologies ? "No technologies listed" : data.Technologies}</p>
    </div>
  );
};
export default Summary;
