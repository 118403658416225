import GoogleLogin from "react-google-login";
import auth from "../../auth";
import mainLogo from "../../assets/connectedlogo.png";
import "./Login.css";
import tree from "../../assets/somethinggreat.gif";

export default function Login(props) {
  const handleLogin = async (googleData) => {
    const email = googleData.profileObj.email;
    
    const domain = email.substring(email.lastIndexOf("@") + 1);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_SERVER}/api/v1/auth/google`,
        {
          method: "POST",
          body: JSON.stringify({
            token: googleData.tokenId
          }),
          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      const data = await res.json();
      
      if (data !== undefined && domain === "connected.io") {
        auth.login(() => {
          props.history.push("/search");
        });
      }
    
    } catch (error) {
      
      console.error(error);
    }
    
  };

  return (
    <div className="h-screen w-screen flex sizing_parent justify-center items-center ">
      <img
        src={tree}
        className="w-80 tree lg:mr-16 lg:pb-10 md:pb-5 object-cover"
        alt="something great"
      />
      <div className="flex flex-col logo_mobile items-center px-10 h-96 pb-7 ">
        <img src={mainLogo} alt="logo" className="w-96 lg:mt-10 logo md:mt-0" />
        <h1 className="lg:text-4xl text-blue mt-2 subheading lg:mb-12 md:mb-5 md:text-2xl sm:text-xl font-connected">
          Project Directory
        </h1>
        <h2 className="text-primary dark:text-secondary text-center md:text-lg lg:mb-10 sm:text-base md:mb-10 sm:my-5 caption font-connected">
          Welcome to the Connected Project Directory, please login to continue
        </h2>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          buttonText="Login"
          onSuccess={handleLogin}
          onFailure={handleLogin}
          cookiePolicy={"single_host_origin"}
          className="w-24"
        
        ></GoogleLogin>
      </div>
    </div>
  );
}
