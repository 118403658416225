import "./Spinner.css";

const Spinner = () => {
  return (
    <div className="loader">
      <div className="loader-inner">
        <div className="loader-inner"></div>
        <div className="bg-secondary dark:bg-primary quarter-circle-bottom-right">
          <div className="bg-secondary dark:bg-primary rectangle-chunk"></div>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
